<template>
  <!--  v-if="!!info" -->
  <div class="footer" :style="'backgroundImage:url(' + require('@/assets/img/footBJ.jpg') + ')'">
    <div class="footCont">
      <div class="footContOne">
        <div>
          <div v-for="(item, index) in $t('publicFoot.AboutUs')" :key="index" :class="index == 0 ? 'tipOne' : 'tipTwo'"
            @click="index == 0 ? '' : jumpPages(item)"> {{ item.name }} </div>
        </div>
        <div>
          <div v-for="(item, index) in $t('publicFoot.services')" :key="index" :class="index == 0 ? 'tipOne' : 'tipTwo'"
            @click="index == 0 ? '' : jumpPages(item)"> {{ item.name }} </div>
        </div>
        <img src="@/assets/img/logo.png" alt="" class="logo" />
        <div class="oneFive">
          <div class="tipOne">{{ $t("publicFoot.tipOne") }}</div>
          <div class="tipTwo tipThree">{{ info.tel }}</div>
          <div class="tipOne">{{ $t("publicFoot.tipTwo") }}</div>
          <div class="tipTwo tipFour"> {{ info.address }} </div>
        </div>
        <div>
          <div class="tipFive">
            <img :src="info.qygzh" alt="" class="QRcode" />
            <div v-html="$t('publicFoot.tipFour')"></div>
          </div>
          <div class="tipFive">
            <img :src="info.qywx" alt="" class="QRcode" />
            <div v-html="$t('publicFoot.tipThree')"></div>
          </div>
        </div>
      </div>
      <div class="footContTwo">
        <div>版权所有© 北京超弦存储器研究院</div>
        <div>ICP备案号：京ICP备2022014639号</div>
        <!-- <div>联网备案号：暂未提供</div> -->
        <div>合规举报邮箱：ethics@bjsamt.org.cn</div>
        <div>新闻媒体邮箱：info@bjsamt.org.cn</div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：公共底部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-03-14 10:57
 */
export default {
  data() {
    return {
      info: "",
    };
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    // 关于我们。底部信息
    getInfo() {
      this.$request({
        url: "/Index/info",
      }).then((res) => {
        // console.log("关于我们", res);
        this.info = res;
      });
    },

    /**
     * 关于我们跳转
     * 刘嘉鑫
     * 2022-8-24
     */
    jumpPages(item) {
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.footer {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  width: 100%;
  padding: 120px 0 70px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  .footCont {
    padding: 0 300px;

    .footContOne {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .oneFive {
        width: 22%;
      }

      .tipOne {
        font-size: 22px;
        font-family: FZFengYaSongS-GB;
        font-weight: bold;
        color: #1a2a60;
        margin-bottom: 30px;
      }

      .tipTwo {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-top: 14px;
        cursor: pointer;
      }

      .logo {
        width: 125px;
        height: 50px;
      }

      .tipThree {
        margin-bottom: 40px;
      }

      // .tipFour {
      //   width: 70%;
      // }

      .tipFive {
        display: flex;
        align-items: center;
        margin-bottom: 42px;

        div {
          font-size: 12px;
          font-family: FZFengYaSongS-GB;
          font-weight: bold;
          color: #1a2a60;
        }
      }

      .tipFive:last-child {
        margin-bottom: 0 !important;
      }

      .QRcode {
        width: 90px;
        height: 90px;
        background: #ffffff;
        margin-right: 10px;
      }
    }

    .footContTwo {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 140px;

      div {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-left: 30px;
      }
    }
  }
}
</style>