<template>
  <div id="app">
    <publicHead></publicHead>
    <router-view class="appCont" v-if="isRouterAlive" :key="$route.fullPath" />
    <publicFoot v-show="$route.meta.footShow" ref="usernameInput"></publicFoot>
  </div>
</template>

<script>
import publicHead from "@/components/publicHead.vue";
import publicFoot from "@/components/publicFoot.vue";

export default {
  components: {
    publicHead,
    publicFoot,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  mounted() {
    console.log(process.env.NODE_ENV);
    // window.addEventListener("scroll", this.handleScrollbox, true);
    // this.handleScrollbox();
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      console.log("刷新当前页面");
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
        this.$refs.usernameInput.getInfo();
      });
    },

    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= 0) {
        this.$(".head").addClass(
          "animate__animated animate__fadeInTop"
        );
        // this.$(".listItemRight").addClass(
        //   "animate__animated animate__fadeInRight"
        // );
      } else {
        this.$(".head").removeClass(
          "animate__animated animate__fadeInTop"
        );
        this.$(".listItemRight").removeClass(
          "animate__animated animate__fadeInRight"
        );
      }
    },
  },
};
</script>

<style>
/*每个页面公共css */
@import "./assets/css/common.css";
</style>
