module.exports = {
	publicHead: {//头部
		language: "en",
		signInTip: "用户登录",
		titleList: [
			{
				title: "社交媒体",
			},
			// {
			// 	title: "隐私说明",
			// 	path: "privacyStatement",
			// 	type: 1
			// },
			{
				title: "网站声明",
				path: "privacyStatement",
				type: 2
			},
			{
				title: "网站地图",
				path: "Sitemap",
			},
		],
	},
	publicFoot: { //底部
		AboutUs: [
			{
				name: "关于我们",
			},
			{
				name: "简介",
				path: "briefIntroduction"
			},
			{
				name: "使命/愿景/价值观",
				path: "missionvision-values"
			},
			{
				name: "我们的团队",
				path: "ourTeam"
			},
			{
				name: "发展大事记",
				path: "memorabilia"
			},
			{
				name: "资质和荣誉",
				path: "qualificationHonor"
			}
		],
		services: [
			{ name: "行业服务" },
			{ name: "研发服务", path: "R&DService" },
			{ name: "人才培养", path: "personnelTraining" },
			{ name: "专利服务", path: "patentService" },
			{ name: "学术活动", path: "academicActivities" },
			{ name: "行业科普", path: "sciencePopularization" },
		],
		tipOne: "保持联系",
		tipTwo: "地址",
		tipThree: "企<br />业<br />微<br />信",
		tipFour: "企<br />业<br />公<br />众<br />号",
	},
	index: { //首页
		index_modular: [{
			icon: require("@/assets/img/yffw.png"),
			title: "研发服务",
			titleTwo: "专业团队和研发能力",
			path: "R&DService"
		}, {
			icon: require("@/assets/img/rcpy.png"),
			title: "人才培养",
			titleTwo: "产教融合项目制学习",
			path: "personnelTraining"
		}, {
			icon: require("@/assets/img/zllm.png"),
			title: "专利服务",
			titleTwo: "合作发展与专利创新",
			path: "patentService"
		}, {
			icon: require("@/assets/img/xshd.png"),
			title: "学术活动",
			titleTwo: "行业沟通与技术交流",
			path: "academicActivities"
		}, {
			icon: require("@/assets/img/hykp.png"),
			title: "行业科普",
			titleTwo: "产业洞察与知识普及",
			path: "sciencePopularization"
		}],
		index_everydayTJ: "每日推荐",
		index_hotspotTJ: "热点推荐",
		index_news: "头条新闻",
		index_instituteOne: "研究院介绍",
		index_instituteTwo: "使<br/>命<br/><p></p>愿<br/>景<br/><p></p>价<br/>值<br/>观",
		index_instituteThree: "我<br/>们<br/>的<br/>团<br/>队",
		index_instituteFour: "资<br/>质<br/>和<br/>荣<br/>誉",
		index_more: "了解更多",
		index_RDprojectOne: "研发项目",
		index_RDprojectTwo: "超弦研究院的研究方向集中于DRAM制造技术的尺寸微缩（down-scaling），在DRAM新架构、新材料、新工艺、先进封装、先进光刻技术、近存计算和高带宽存储等领域，对未来DRAM量产技术可能存在的主要路径开展系统的工艺研发和设计-制造协同优化。",
		index_academy: "部门设置",
		index_learning: "学术活动",
		index_employ: "聘<br/>请",
	},

	aboutUs: { // 关于我们
		aboutUs_titleOne: "超弦研究院",
		aboutUs_titleTwo: "简介",
		aboutUs_aboutList: [
			{
				name: "使命  愿景  价值观",
				path: "missionvision-values"
			},
			{
				name: "我们的团队",
				path: "ourTeam"
			},
			{
				name: "资质和荣誉",
				path: "qualificationHonor"
			}, {
				name: "发展大事记",
				path: "memorabilia"
			}
		],
		aboutUs_see: "查看"
	},

	qualificationHonor: { // 关于我们-资质和荣誉
		QH_tipOne: "资质和荣誉",
		QH_tipTwo: "协会/学会"
	},

	ourTeam: { // 关于我们-我们的团队
		team_tipOne: "我们的团队",
		team_tipTwo: "技术咨询委员会",
		team_tipList: [
			{
				icon: require("@/assets/img/yi.png"),
				title: "核心团队成员平均拥有超过20年的国际领先芯片企业和研发机构的研发管理经验， 擅长领域覆盖存储器芯片设计、架构、工艺和先进光刻等领域；"
			},
			{
				icon: require("@/assets/img/er.png"),
				title: "技术骨干团队成员包括多名拥有超过15年生产线工作经验的技术专家，擅长领域覆盖光刻、刻蚀、薄膜、注入、炉管、CMP等工艺模块，以及工艺集成、DET、PET等关键领域；"
			},
			{
				icon: require("@/assets/img/san.png"),
				title: "初中级研发团队拥有一大批国内外一流高校毕业的博士及硕士研究生。"
			}
		],
	},

	missionvisionvalues: { // 关于我们-使命愿景价值观
		MVV_tipOne: "使命",
		MVV_tipTwo: "聚焦存储器制造技术的路径探索，建立由企业主导、产学研结合的创新体系，推动存储器技术的持续迭代。",
		MVV_tipThree: "愿景",
		MVV_tipFour: "成为世界存储器芯片的技术创新高地、知识产权高地、人才培养高地及国际合作桥梁。 ",
		MVV_tipFive: "价值观"
	},

	briefIntroduction: { // 关于我们-简介
		BI_tip: "简介"
	},

	memorabilia: { // 关于我们-发展大事记
		mb_tip: "发展大事记"
	},

	recruitment: { // 人才招聘
		RT_textOne: "多元化 平等与尊重 工作生活平衡",
		RT_textTwo: "研究院关注人才培养，也为青年人才职业发展提供了绝佳的平台——这里有富有产业经验的业务导师、鼓励创新与包容个性的文化氛围、12英寸DRAM制造硬件平台、与工业产线和国内最优秀的学术机构的密切合作机制、参加面向量产技术研发的大型项目实战机会……超弦研究院热忱欢迎国内外青年才俊加入我们，在成就伟大事业的过程中，实现最好的自己。",
		RT_modular: [
			{
				name: "人才政策",
				path: "talentPolicy"
			},
			{
				name: "人才招聘",
				path: "talentRecruitment"
			},
			{
				name: "薪酬福利 培训与发展",
				path: "salaryWelfare"
			},
			{
				name: "工作环境",
				path: "workEnvironment"
			},
			{
				name: "员工故事",
				path: "employeeStories"
			},
			{
				name: "人才联合培养计划",
				path: "personnelTraining"
			},
		]
	},

	talentPolicy: { // 人才招聘-人才政策
		TP_tipOne: "我们的愿景、使命与价值观建立的基础是人才的多元化和彼此尊重。创新与合作是我们的价值观，这些将由我们多元化的人才来创造。我们拥抱多元化，多元化的人才队伍是践行价值观的基础。",
		TP_tipTwo: "我们希望建立所有员工之间的平等关系。我们尊重员工在性别、宗教信仰、种族、年龄、国籍等方面的差异。我们致力于创造一个所有员工共享共建、无歧视的工作环境，让所有人都能展现出最好的自己。",
		TP_tipThree: "",
		TP_tipFour: "员工的身心健康对我们来说至关重要，我们希望员工既能享受工作带来的成就感，也能感受幸福生活的喜悦。我们希望和员工建立长期共赢的关系，为员工提供事业发展的舞台，和员工一起成长，共创美好未来。",
		TP_tipFive: "",
		TP_tipSix: "多元化",
		TP_tipSeven: "平等与尊重",
		TP_tipEight: "工作生活平衡",
	},

	salaryWelfare: { // 人才招聘-薪酬福利 培训发展
		SW_tipOne: "薪酬福利",
		SW_modular: ["行业有竞争力的薪酬，六险一金", "北京户口", "交通补贴、餐补", "人才公寓<br>（博士单间）", "带薪年假（法定+福利）", "年度体检", "节日礼金、生日慰问金"],
		SW_tipTwo: "培训与发展",
		// SW_modularTwo: [
		// 	{
		// 		title: "On the job training 国际顶尖导师在职指导",
		// 		img: require("@/assets/img/mvv_aaa.png")
		// 	},
		// 	{
		// 		title: "Off-work courses 国家人才计划的专家培训课程 ",
		// 		img: require("@/assets/img/mvv_aaa.png")
		// 	},
		// 	{
		// 		title: "参加一流国际/国内学术会议 ",
		// 		img: require("@/assets/img/mvv_aaa.png")
		// 	},
		// 	{
		// 		title: "申请专利奖励计划  ",
		// 		img: require("@/assets/img/mvv_aaa.png")
		// 	}
		// ]
	},

	workEnvironment: { // 人才招聘-工作环境
		WE_message: "院长寄语",
		WE_messageTwo: "感谢我们的员工选择超弦研究院作为事业发展的平台。研究院自身的快速成长为青年人才职业生涯的发展提供了绝佳的平台——这里有富有产业经验的业务导师，鼓励创新与包容个性的文化氛围，先进的12英寸DRAM制造硬件平台，与工业产线和国内最优秀的学术机构的密切合作机制，参加面向量产技术研发的大型项目实战机会……超弦研究院热忱欢迎国内外青年才俊加入我们，在成就伟大事业的过程中，实现最好的自己。",
		WE_tipOne: "办公环境",
		WE_tipOneOne: "每个活力满满的早上，迎面而来的是京东贝科技园的四季繁花和清新空气。清晨的第一缕阳光洒满书桌，我们就这样开始卓有成效的一天。",
		WE_tipTwo: "员工俱乐部",
		WE_tipTwoTwo: "羽毛球俱乐部、读书会、健身俱乐部丰富了我们的业余生活，放松身心、增进友谊。",
		WE_tipThree: "团建活动",
		WE_tipThreeThree: "创意多多的团建活动，带给我们不一样的人生体验。"
	},

	employeeStories: { // 人才招聘-员工故事
		ES_modular: [
			// {
			// 	name: "金俊昊",
			// 	img: require('@/assets/img/esBJ1.png'),
			// 	// <br>我为什么选择超弦？
			// 	tipOne: "高端国际人才",
			// 	tipTwo: "大家好，我来自韩国利川。能来到研究院工作，我感到非常荣幸。我有一个非常幸福美满的家庭，家庭成员有我太太，一对可爱的双胞胎女儿和一只调皮的小狗。他们也将在今年年末来到中国。超弦研究院孕育着存储器行业的未来，研究院的文化包容、鼓励创新、以人为本，我希望能和来自比利时、新加坡、美国的各位科学家一起，为推动行业未来的发展贡献自己的微薄之力。"
			// },
			{
				name: "张锋",
				img: require('@/assets/img/esBJ2.png'),
				// <br>我为什么选择超弦？
				tipOne: "有经验的职场人士",
				tipTwo: "我来自于内蒙古呼伦贝尔，先后就职于中国电子科技集团和英特尔公司，研究方向主要为先进光刻工艺。我的兴趣爱好是读书、秦琴，人生愿景是在前进中成为自己。超弦研究院汇聚了志同道合的一群人，我们彼此支持，互相学习，能够真正从事一些探索性、实验性、对行业未来发展有影响力的项目。研究院在住房、学籍、户口等生活方面安排的很细致，也十分注重和谐、轻松的工作氛围，我对这份工作感到十分满意。"
			},
			{
				name: "Mary Li",
				img: require('@/assets/img/esBJ3.png'),
				// <br>我为什么选择超弦？
				tipOne: "应届生",
				tipTwo: "我本科毕业于北京科技大学材料科学与工程学院，后在美国威廉玛丽学院获得了物理学博士学位。我平时喜欢健身、游泳、跑步、爬山、排球等项目，也非常喜欢看一些科幻、悬疑、推理类的电影、书籍。来到超弦研究院，遇到很多行业大师，能在他们的指导下工作，在工业产线上进行项目制的学习，还能参加国内培训课程和国际学术会议，我感到自己成长的速度太快了！"
			}
		]
	},

	talentRecruitment: { // 人才招聘-人才招聘
		TR_navList: [
			{ name: "社会招聘", type: 0 },
			{ name: "校园招聘", type: 1 },
			{ name: "人才联合培养博士/硕士", type: 2 }
		],
		TR_tipOne: "聘请",
		TR_tipOne_br: "聘<br/>请",
		TR_tipTwo: "查看",
		TR_tipThree: "收起",
		TR_tipFour: "人",
	},

	industryService: { // 行业服务
		IS_navList: [
			{
				name: "研发服务",
			},
			{
				name: "人才培养",
			},
			{
				name: "专利服务",
			},
			{
				name: "学术活动",
			},
			{
				name: "行业科普",
			},
		],
		IS_titleOne: "北京超弦存储器研究院为推动存储器行业的整体发展提供全面服务——建立行业公共研发平台、提供技术服务、培养行业人才、提供专利服务、举办学术活动、开展科普宣传、促进学术交流与国际合作，扩大存储器行业的社会影响力。",
		IS_modular: [
			{
				tip: "研发服务",
				title: "北京超弦存储器研究院为存储器行业提供公共研发平台与研发服务。",
				see: "查看",
				path: "R&DService"
			},
			{
				tip: "人才培养",
				title: "北京超弦存储器研究院与国内顶级高校、研究所展开合作，建设人才培养高地，为行业储备高素质人才。",
				see: "查看",
				path: "personnelTraining"
			},
			{
				tip: "专利服务",
				title: "北京超弦存储器研究院研究推进和落实全产业链的知识产权合作。",
				see: "查看",
				path: "patentService"
			},
			{
				tip: "学术活动",
				title: "北京超弦存储器研究院定期举办国内/国际学术会议，促进行业学术交流与国际合作。",
				see: "查看",
				path: "academicActivities"
			},
			{
				tip: "行业科普",
				title: "北京超弦存储器研究院推动存储器行业的科普宣传，助力扩大行业的社会影响力。",
				see: "查看",
				path: "sciencePopularization"
			},
		]
	},

	personnelTraining: { // 行业服务-人才培养
		PT_tipOne: "北京超弦存储器研究院人才联合培养",
		PT_tipTwo: "北京超弦存储器研究院建有完善的人才培养机制，致力于在教育与产业之间搭建桥梁，推动产教深度融合。作为北京经济技术开发区产教融合基地的重要参与单位，研究院与清华大学、北京大学和中国科学院大学在人才联合培养方面开展了系统而紧密的合作，每年可为行业培养大量集成电路高端人才。",
		PT_tipThree: "北京超弦存储器研究院的人才培养计划具备以下办学优势",
		PT_tipFour: "欢迎参与超弦存储器研究院的产教融合项目，一同成就梦想。",
		PT_modular: [
			{
				name: "问题导向的研究课题",
				icon: require("@/assets/img/yi.png")
			},
			{
				name: "经验丰富的企业导师团队",
				icon: require("@/assets/img/er.png")
			},
			{
				name: "世界先进的研发条件",
				icon: require("@/assets/img/san.png")
			},
			{
				name: "丰富的教学和培训课程",
				icon: require("@/assets/img/si.png")
			},
			{
				name: "学生浸润于产业化研发的工作氛围",
				icon: require("@/assets/img/wu.png")
			},
			{
				name: "完成学业后，可加入研究院或推荐到领先的存储器设计及制造企业工作",
				icon: require("@/assets/img/liu.png")
			},
		]
	},

	patentService: { // 行业服务-专利服务
		PS_modular: [
			{
				title: "北京超弦存储器研究院立院之初就非常重视知识产权工作，将建设“知识产权高地”作为研究院的愿景之一。为此，研究院组建了一支专业化的知识产权团队。"
			},
			{
				title: "知识产权团队立足于行业现状，跟踪技术发展趋势、预警知识产权风险、推进知识产权布局，致力于提升行业知识产权保护能力、风险防御能力及高价值专利运营能力。"
			},
			{
				title: "知识产权团队由法律、技术、资讯分析和管理等方面的专业人员组成，已经开展了专利挖掘和布局、专利预警、专利数据库建设、知识产权培训等方面的工作，并持续推动知识产权合作平台建设。"
			},
		],
		PS_TipOne: "专利资质",
		PS_TipTwo: "专利服务"
	},

	sciencePopularization: { // 行业服务-行业科普
		SP_tipOne: "行业科普",
		SP_modular: [
			{
				name: "存储器发展大事记",
				img: "",
				see: "查看",
				path: "storageDevelopment"
			},
			// {
			// 	name: "集成电路名人堂",
			// 	img: "",
			// 	see: "查看"
			// },
			{
				name: "DRAM是什么",
				img: "",
				see: "查看",
				path: "bannerDetails"
			},
			{
				name: "科普新闻",
				img: "",
				see: "查看",
				path: "scienceNews"
			},
			// {
			// 	name: "图书馆",
			// 	img: "",
			// 	see: "查看",
			// 	path: "library"
			// },
		],
		SP_modularTwo: [
			{
				name: "存储器发展大事记",
				img: "",
				see: "查看",
				path: "storageDevelopment"
			},
			// {
			// 	name: "集成电路名人堂",
			// 	img: "",
			// 	see: "查看"
			// },
			{
				name: "DRAM是什么",
				img: "",
				see: "查看",
				path: "bannerDetails"
			},
			// {
			// 	name: "科普新闻",
			// 	img: "",
			// 	see: "查看",
			// 	path: "scienceNews"
			// },
			{
				name: "图书馆",
				img: "",
				see: "查看",
				path: "library"
			},
		],
		library: { // 行业服务-行业科普-图书馆
			L_download: "下载",
			L_tipOne: "姓名：",
			L_tipTwo: "标签：",
		},
		scienceNews: { // 行业服务-行业科普-图书馆
			SN_tip: "科普新闻",
			SN_see: "查看"
		},
		storageDevelopment: { // 行业服务-行业科普-储存器发展大事记
			SD_tip: "存储器发展大事记 "
		}
	},

	RDService: { // 行业服务-研发服务
		RDS_tipOne: "EDA 工具验证与导入<br/>TEG 设计与版图生成<br/>PDK 开发与验证",
		RDS_tipTwo: "集成电路产教融合高<br/>端人才培养",
		RDS_tipThree: "集成电路先进工艺<br/>流片和技术咨询",
		RDS_tipFour: "定制化晶圆衬底和<br/>图形结构片加工",
		RDS_tipFive: "研发服务",
		RDS_tipSix: "集成电路存储器先进<br/>工艺和技术专利分析",
		RDS_tipSeven: "装备和材料验证",
		RDS_tipEight: "半导体材料表征和<br/>晶圆级电性测试服务",
	},

	TechnologyRD: { // 技术研发
		RD_navList: [
			{
				name: "研发方向",
			},
			{
				name: "研发条件",
			},
			{
				name: "部门设置",
			},
			{
				name: "科研成果",
			},
		],
		RD_titleOne: "北京超弦存储器研究院根据产业发展趋势开展面向未来的存储器芯片技术研发，聚焦存储器新架构、新材料、新工艺等领域，超前布局一批革命性创新技术，集中攻克一批关键核心技术，落地转化一批成熟技术，推动存储器产业长远发展。",
		RD_modular: [
			{
				tip: "研发方向",
				title: "北京超弦存储器研究院为存储器行业提供公共研发平台与研发服务。",
				see: "查看",
				path: "researchDirection"
			},
			{
				tip: "研发条件",
				title: "超弦研究院拥有12英寸DRAM研发所需的硬件条件，包括传统的DRAM 全流程基础工艺和一系列由特种装备构成的短流程特色工艺；设计制造协同优化所需的软件平台；以及具有丰富的芯片工艺和设计研发经验的技术团队。",
				see: "查看",
				path: "RDconditions"
			},
			{
				tip: "部门设置",
				title: "北京超弦存储器研究院技术研发部下设设计与产品部、先进光刻技术研发部、新材料与装备验证部、先进工艺研发部、工艺集成和先进封装技术研发部等部门。",
				see: "查看",
				path: "departmentSettings"
			},
			{
				tip: "科研成果",
				title: "北京超弦存储器研究院的科研成果的主要形式是为国内DRAM企业提供技术迭代的解决方案。在研发过程中的科学发现将以论文的形式发表，技术创新成果则体现为专利申请。",
				see: "查看",
				path: "scientificAchievements"
			},
		]
	},

	RDconditions: { // 技术研发-研发条件
		RD_tipOne: "研发条件",
		RD_tipTwo: "超弦研究院拥有12英寸先进DRAM研发所需的硬件条件，包括传统的1X DRAM 全流程基础工艺和一系列由特种装备构成的短流程特色工艺；设计制造协同优化所需的软件平台；以及具有丰富的芯片工艺和设计研发经验的技术团队。",
	},

	scientificAchievements: { // 技术研发-科研成果
		SA_tipOne: "科研文章",
		SA_tipTwo: "科研专利",
		SA_tipThree: "期刊",
		SA_see: "查看"
	},

	researchDirection: { // 技术研发-研发方向
		RD_tipOne: "研发方向",
		RD_titleOne: "超弦研究院专注于DRAM制造技术研发，在DRAM新架构、新材料、新工艺、先进封装、光刻技术、近存计算和高带宽存储等领域，对未来DRAM量产技术可能存在的主要路径开展系统的工艺研发和设计-制造协同优化。",
		RD_titleTwo: "目前主要的研究项目包括垂直围栅晶体管动态随机存储器、铟镓锌氧场效应晶体管动态随机存储器、横向堆叠单元存储器、垂直围栅晶体管磁存储器等基于新器件结构的存储器制造技术；基于混合键合技术的高带宽存储器芯片和近存计算、存内计算等芯片的设计和制造；以及DRAM制造中的关键光刻技术和设计技术方法学研究等。",
		RD_tipTwo: "研发项目",
		RD_see: "查看"
	},

	departmentSettings: { // 技术研发-部门设置
		DS_tipOne: "部门设置",
		DS_modular: [
			{
				img: require('@/assets/img/dsBJ1.png'),
				title: "设计与产品部",
				// titleTwo: "将构想转化为现实。",
				type: 1,
				see: "查看"
			},
			{
				img: require('@/assets/img/dsBJ2.png'),
				title: "先进光刻技术研发部",
				// titleTwo: "光刻是半导体制造中的关键一步。",
				type: 2,
				see: "查看"
			},
			{
				img: require('@/assets/img/dsBJ3.png'),
				title: "工艺研发平台部",
				// titleTwo: "材料和装备是半导体产业的基石。",
				type: 3,
				see: "查看"
			},
			{
				img: require('@/assets/img/dsBJ4.png'),
				title: "工艺集成部",
				// titleTwo: "先进工艺是版图设计与生产制造之间的桥梁。",
				type: 4,
				see: "查看"
			},
			{
				img: require('@/assets/img/dsBJ5.png'),
				title: "新材料与装备验证部",
				// titleTwo: "项目管理部是研究院产学研结合的纽带。",
				type: 5,
				see: "查看"
			},
			{
				img: require('@/assets/img/dsBJ6.png'),
				title: "先进封装技术研发部",
				// titleTwo: "",
				type: 6,
				see: "查看"
			},
		]
	},

	designProductDepartment: { // 技术研发-部门设置-设计与产品部
		DPD_oneTitle: "设计与产品部",
		DPD_oneCont: "电路设计不仅定义产品性能，对良率提升、成本优化、可靠性改善、产品标准化也有重大影响。设计与产品部（Design & Product）负责设计、器件、验证、测试、存储架构的研发，与工艺部门协调开展前瞻性的存储芯片技术探索。",
		DPD_twoTip: ["研究不是目的地。", "设计是技术/产品商业化的关键一步。", "产品是连接设计和市场的桥梁。"],
		DPD_threeTip: "设计的角色",
		DPD_fourTipOne: "业务范围",
		DPD_fourContOne: "设计与产品部",
		DPD_fourContOneOne: "分为设计组（Design）、计算机辅助工程组（CAE）、设计实现组（DET）和系统组（System）。",
		DPD_fourTipTwo: "设计组 （Design Group）",
		DPD_fourContTwo: "设计组负责存储芯片技术的功能验证、设计/工艺协同优化、电路设计、仿真、版图、验证、流片和知识产权布局等工作。",
		DPD_fourTipThree: "计算机辅助工程组（CAE Group）",
		DPD_fourContThree: "计算机辅助工程组（CAE，Computer Aided Engineering）主要负责DRAM设计方法学，即计算机辅助设计（CAD）和器件（Device）等方面的工作。同时，依托器件物理与工艺理论和经验，开展器件仿真，分析器件电性和工艺之间的关系，为制造工艺研究提供支撑。",
		DPD_fourTipFour: "设计实现组（DET Group）",
		DPD_fourContFour: "设计实现组负责器件规格设置，测试单元规划、工艺与器件仿真、工艺设计套件以及器件模型开发，并对多种设计方案进行工艺协同优化，以提前预知器件特性与电路可行性，提升投片成功率。同时，团队联合EDA工具厂商、电路设计工程师、代工厂在研发过程中共同开展EDA工具特定功能的开发和验证，推动EDA工具发展。",
		DPD_fourContFourOne: "DET组将联合EDA厂商、电路设计者和代工厂一起共同解决EDA工具面临的挑战。作为EDA厂商、代工厂和设计者之间沟通纽带，DET部门具体执行包括工艺设计套件、器件模型、版图设计及工艺与器件仿真、国产EDA的导入与验证等在内的多项工作。我们将器件设计、工艺与器件仿真、工艺设计套件以及器件模型等功能有机结合在一起，对多种设计方案进行工艺协同优化，产生最终电路设计所需的关键数据库。",
		DPD_fourTipFive: "系统组（System Group） ",
		DPD_fourContFiveOne: "系统组聚焦于以数据为中心的新型计算-存储架构核心技术的开发，以增加计算的并行度、减轻对现有计算、内存、外存和I/O的限制、减少因巨量数据搬运而带来的能量消耗。同时，团队与其他部门共同开展新型存储架构的研发和技术路径探索。",
		DPD_fourContFiveTwo: "以数据为中心的新型计算-存储架构",
		DPD_fourContFiveThree: "",
		DPD_fourContFiveFour: "系统组的研究方向",
		DPD_fourContFiveFive: [{
			name: "基于DRAM的近存计算",
			img: require('@/assets/img/DPDimg7.png'),
		}, {
			name: "计算型存储",
			img: require('@/assets/img/DPDimg8.png'),
		}, {
			name: "DRAM迭代技术与新型<br>存储技术中的架构问题",
			img: require('@/assets/img/DPDimg9.png'),
		}, {
			name: "CXL DRAM",
			img: require('@/assets/img/DPDimg10.png'),
		}]
	},

	lithographySection: { // 技术研发-部门设置-先进光刻技术研发部
		LS_oneTitle: "先进光刻技术研发部",
		LS_oneCont: "光刻工艺是集成电路制造的核心技术，也是摩尔定律演进的关键推手。光刻技术的发展推动了芯片工艺关键尺寸的持续微缩，使得集成电路不断向更小的技术节点演进，以制造出更高性能、更高密度、更低功耗的芯片产品。光刻技术研发部一方面进行多种先进光刻技术路线预研，支撑新架构、新器件、新材料的研发和工艺集成；另一方面在光源光罩协同优化、光学临近效应修正、设计制造协同优化、先进光罩生产制造、基于机器学习的效能提升、新型光刻材料与光刻设备验证等方面开展系统工作，全方位推动存储器芯片技术研发进程。",
		LS_threeTip: "业务范畴",
		LS_threeModular: [
			{
				name: "先进光刻工艺研发:<br/>先进工艺中成像<br/>精准对准<br/>精准套刻",
				img: require('@/assets/img/lsThreeImg1.png'),
			}, {
				name: "微影成像提升、光学优化<br/>亚分辨辅助图形的机器学习",
				img: require('@/assets/img/lsThreeImg2.png'),
			}, {
				name: "DRAM以及新兴存储器新产品<br/>光刻工艺研发",
				img: require('@/assets/img/lsThreeImg3.png'),
			}, {
				name: "国产化光刻材料与设备验证",
				img: require('@/assets/img/lsThreeImg4.png'),
			}, {
				name: "光学邻近效应修正优化",
				img: require('@/assets/img/lsThreeImg5.png'),
			}, {
				name: "光刻产学研平台<br/>产教融合与人才培养",
				img: require('@/assets/img/lsThreeImg6.png'),
			},
		],
		LS_fourTipOne: "图形化增强技术",
		LS_fourContOne: ["核心阵列", "标准单元", "IP 模块", "测试设计版图", "已有产品版图", "产品类似芯片版图", "关键图形"],
		LS_fourContTwo: ["Dummy填充和优化", "光刻友好设计", "CMP建模和分析", "关键区域分析", "弱点图形库"],
		LS_fourContThree: ["光刻仿真", "光源优化", "OPC模型", "OPC修正", "OPC修正后检查"],
		LS_fourTipTwo: "OPC细化",
		LS_fourContFour: ["Layer逻辑运算", "Mask版图设计", "光掩模制版数据", "JDV/MRC/MCD", "Mask订单和交付", "Mask质量监控", "MPC"],
		LS_fourTipThree: "设计与微影流程",
	},

	processIntegrationDepartment: { // 技术研发-部门设置-工艺集成部
		PID_oneTitle: "工艺集成部",
		PID_oneCont: "工艺集成部是项目研发的核心力量，负责研发工艺流程制定、流片安排和在线管理；芯片测试结果跟踪、问题的分析和解决；并协助进行版图设计和变更，推动研发项目的设计定案。",
		PID_fourTipOne: "业务范畴",
		PID_fourContOne: ["项目组织实施", "新工艺评估、流程建立", "制程整合、优化", "研发Lot管理", "结构验证电性测试与分析", "Tape-out支持"],
		PID_fourTipTwo: "研发项目",
	},

	processPlatformDepartment: { // 技术研发-部门设置-工艺研发平台部
		PPD_oneTitle: "工艺研发平台部",
		PPD_oneCont: "工艺研发平台负责先进DRAM工艺流程中各工艺模块开展工艺的研究和开发，为工艺集成准备好全套的工艺菜单。",
		PPD_twoTitle: "工艺平台研发部的工作标准是<text style='color: #283b7e !important;'>追求细节与完美</text>",
		PPD_threeTitle: "支持研发所有项目",
		PPD_threeModular: [
			{
				name: "薄膜",
				img: require('@/assets/img/ppdThreeImg1.png'),
			}, {
				name: "扩散",
				img: require('@/assets/img/ppdThreeImg2.png'),
			}, {
				name: "光刻",
				img: require('@/assets/img/ppdThreeImg3.png'),
			}, {
				name: "刻蚀",
				img: require('@/assets/img/ppdThreeImg4.png'),
			}, {
				name: "CMP",
				img: require('@/assets/img/ppdThreeImg5.png'),
			}, {
				name: "清洗",
				img: require('@/assets/img/ppdThreeImg6.png'),
			},
		],
		PPD_threeTip: "<text style='font-weight: bold;'>工艺研发平台部探索六大工艺模块（薄膜、扩散、光刻、刻蚀、化学研磨、清洗）中的新工艺，通过协同优化，形成最佳工艺方法。",
		PPD_fourTip: "工艺研发平台部的优势",
		PPD_fourModular: [
			{
				name: "具有丰富行业经验的资深工程师队伍。<br/> <br/>12英寸先进DRAM工艺开发硬件平台。",
				img: require('@/assets/img/ppdImg3.png'),
			},
			{
				name: "先进DRAM基础工艺。<br/> <br/>多台套面向新材料新工艺的先进装备。",
				img: require('@/assets/img/ppdImg4.png'),
			}
		]
	},

	materialPackaging: { // 技术研发-部门设置-新材料与国产装备验证部
		MP_oneTitle: "新材料与装备验证部",
		MP_oneCont: "半导体材料和制造装备是集成电路技术节点迭代的关键。新材料与装备验证部门在存储领域超前布局新材料研发，集中攻克材料应用核心技术，建立从材料到系统的协同优化平台，持续提升器件性能。部门与材料和设备厂商合作，共同开展设备关键技术研发，拓展设备工艺能力，推动产业生态建设。",
		MP_twoTipOne: "构建设备材料协同创新生态圈",
		MP_twoTipTwo: "搭建装备和材料验证平台，提供研发助力服务—高质量结构片，定制化图形片。",
		MP_twoModular: ["面向3D-DRAM<br/>的外延工艺", "新供应商的<br/>装备验证", "装备与材料验证", "DRAM新<br/>材料的<br/>ALD工艺", "与供应商<br/>合作开发", "新材料研发", "氧化物半导<br/>体薄膜沉积", "新供应商的<br/>材料验证"],
		MP_threeTipOne: "先进SiGe工艺研发",
		MP_threeContOne: "Si/SiGe外延生长",
		MP_threeContTwo: "Si/SiGe外延层周期性生长是多层横向单元堆叠DRAM技术方案的关键。",
		MP_threeModular: ["表面与界面<br/>分析", "物理尺寸", "SiGe薄膜<br/>材料表征", "薄膜成分<br/>分析", "单晶结构<br/>分析", "应变分析"],
		MP_fourTipOne: "ALD工艺",
		MP_fourContTwo: "原子层沉积（ALD）具有精准的膜厚控制能力和保形性，可以在高深宽比结构和环绕结构上沉积高保形性薄膜，推动单元器件持续小型化。基于氧化物半导体沟道材料、介质和各种金属薄膜的ALD技术正在成为DRAM工艺研究的热点问题。",
		PPD_fiveTip: "IGZO薄膜",
		PPD_fiveCont: "IGZO具有宽禁带和高载流子迁移率，作为晶体管沟道材料可给出超高的开关比，有望实现2T0C DRAM。IGZO薄膜生长是IGZO基DRAM制造的关键，正在成为存储器制造技术的研发热点之一。",
		PPD_sixTip: "材料验证",
		PPD_sixContOne: "2021 年中国集成电路制造材料市场规模构成",
		PPD_sixContTwo: "作为晶圆制造企业和材料供应商之间的桥梁，为供应商提供DRAM制造用材料的验证服务；",
		PPD_sixContThree: "为供应商提供行业技术咨询，帮助供应商开发新材料新工艺所需耗材。",
		PPD_sevenTip: "装备验证",
		PPD_sevenContOne: "2021 年中国集成电路制造装备市场规模构成",
		PPD_sevenContTwo: "为装备供应商提供DRAM工艺的验证服务；",
		PPD_sevenContThree: "与供应商一起，合作开发面向新材料的新型装备；",
		PPD_sevenContFour: "为装备研发提供所需结构片。"
	},

	SItemap: { // 头部网站地图
		SI_tip: "网站地图",
		SI_modular: [
			[
				{
					name: "技术研发"
				},
				{
					name: "研发方向",
					path: "researchDirection"
				},
				{
					name: "研发条件",
					path: "RDconditions"
				},
				{
					name: "部门设置",
					path: "departmentSettings"
				},
				{
					name: "科研成果",
					path: "scientificAchievements"
				},
			],
			[
				{
					name: "行业服务"
				},
				{
					name: "研发服务",
					path: "R&DService"
				},
				{
					name: "人才培养",
					path: "personnelTraining"
				},
				{
					name: "专利服务",
					path: "patentService"
				},
				{
					name: "学术活动",
					path: "academicActivities"
				},
				{
					name: "行业科普",
					path: "sciencePopularization"
				},
			],
			[
				{
					name: "人才招聘"
				},
				{
					name: "人才政策",
					path: "talentPolicy"
				},
				{
					name: "人才招聘",
					path: "talentRecruitment"
				},
				{
					name: "薪酬福利 培训与发展",
					path: "salaryWelfare"
				},
				{
					name: "工作环境",
					path: "workEnvironment"
				},
				{
					name: "员工故事",
					path: "employeeStories"
				},
				{
					name: "人才联合培养计划",
					path: "personnelTraining"
				},
			],
			[
				{
					name: "新闻中心"
				},
				{
					name: "新闻中心",
					path: "newsCenter"
				},
			],
			[
				{
					name: "关于我们"
				},
				{
					name: "简介",
					path: "briefIntroduction"
				},
				{
					name: "使命 愿景 价值观",
					path: "missionvision-values"
				},
				{
					name: "我们的团队",
					path: "ourTeam"
				},
				{
					name: "资质和荣誉",
					path: "qualificationHonor"
				},
				{
					name: "发展大事记",
					path: "memorabilia"
				},
			]
		]
	},

	register: { // 注册
		tipOne: "注册",
		tabbar: ["个人", "单位"]
	},

	newsCenter: { // 新闻中心
		NC_see: "查看"
	}


} 