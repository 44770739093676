module.exports = {
    publicHead: { //头部
        language: "中文",
        signInTip: "User login",
        titleList: [
            {
                title: "Social media",
            },
            // {
            //     title: "Privacy statement",
            //     path: "privacyStatement",
            //     type: 1
            // },
            {
                title: "Website Statement",
                path: "privacyStatement",
                type: 2
            },
            {
                title: "Sitemap",
                path: "Sitemap",
            },
        ]
    },
    publicFoot: { //底部
        AboutUs: [
            {
                name: "About us",
            },
            {
                name: "brief introduction",
                path: "briefIntroduction"
            },
            {
                name: "mission/vision/sense of worth",
                path: "missionvision-values"
            },
            {
                name: "Our team",
                path: "ourTeam"
            },
            {
                name: "Development milestones",
                path: "memorabilia"
            },
            {
                name: "Qualification and honor",
                path: "qualificationHonor"
            }
        ],
        services: [
            { name: "Industry services" },
            { name: "R & D Service", path: "R&DService" },
            { name: "personnel training", path: "personnelTraining" },
            { name: "Patent service", path: "patentService" },
            { name: "academic activities", path: "academicActivities" },
            { name: "Industry science popularization", path: "sciencePopularization" },
        ],
        tipOne: "keep in contact",
        tipTwo: "address",
        tipThree: "Enterprise wechat",
        tipFour: "Enterprise official account"
    },
    index: { //首页
        index_modular: [{
            icon: require("@/assets/img/yffw.png"),
            title: "R & D Service",
            titleTwo: "Professional team and R&D capability",
            path: "R&DService"
        }, {
            icon: require("@/assets/img/rcpy.png"),
            title: "personnel training",
            titleTwo: "Project based learning with integration of production and education",
            path: "personnelTraining"
        }, {
            icon: require("@/assets/img/zllm.png"),
            title: "Patent Services",
            titleTwo: "Cooperative development and patent innovation",
            path: "patentService"
        }, {
            icon: require("@/assets/img/xshd.png"),
            title: "academic activities",
            titleTwo: "Industry communication and technical exchange",
            path: "academicActivities"
        }, {
            icon: require("@/assets/img/hykp.png"),
            title: "Industry science popularization",
            titleTwo: "Industry insight and knowledge popularization",
            path: "sciencePopularization"
        }],
        index_everydayTJ: "Daily recommendation",
        index_hotspotTJ: "Hot spot recommendation",
        index_news: "Headline news",
        index_instituteOne: "Introduction to the Research Institute",
        index_instituteTwo: "Mission<br/><p></p>Vision<br/><p></p>Values",
        index_instituteThree: "Our<br/>team",
        index_instituteFour: "Qualifications<br/>and<br/>Honors",
        index_more: "Learn more",
        index_RDprojectOne: "R & D project",
        index_RDprojectTwo: "The research direction of Superstring Research Institute is focused on the down scaling of DRAM manufacturing technology. In the fields of DRAM new architecture, new materials, new processes, advanced packaging, advanced lithography technology, near memory computing and high bandwidth storage, the Institute conducts systematic process research and development and design manufacturing collaborative optimization on the possible main paths of future DRAM mass production technology.",
        index_academy: "Department Settings",
        index_learning: "academic activities",
        index_employ: "employ"
    },

    aboutUs: { // 关于我们
        aboutUs_titleOne: "Superstring Research Institute",
        aboutUs_titleTwo: "brief introduction",
        aboutUs_aboutList: [
            {
                name: "Mission vision values",
                path: "missionvision-values"
            },
            {
                name: "Our team",
                path: "ourTeam"
            },
            {
                name: "Qualification and honor",
                path: "qualificationHonor"
            }, {
                name: "Development milestones",
                path: "memorabilia"
            }
        ],
        aboutUs_see: "see"
    },

    qualificationHonor: { // 关于我们-资质和荣誉
        QH_tipOne: "Qualification and honor",
        QH_tipTwo: "Association/Institute"
    },

    ourTeam: { // 关于我们-我们的团队
        team_tipOne: "Our team",
        team_tipTwo: "Technical Advisory Committee",
        team_tipList: [
            {
                icon: require("@/assets/img/yi.png"),
                title: "The core team members have an average of more than 20 years of R & D experience in international leading chip enterprises, including Samsung, Hynix, IMEC, ASML and other enterprises and institutions, covering DRAM Design and architecture, chip technology and advanced lithography;"
            },
            {
                icon: require("@/assets/img/er.png"),
                title: "The technical backbone team members include many technical experts with more than 15 years of Fab work experience on average. Their work experience includes Micron, Samsung, Intel, Lattice, Liandian, SMIC International, Huali and other enterprises, covering photolithography, etching, film, injection, furnace tube, CMP and other process modules, process integration, DET, PET and other fields;"
            },
            {
                icon: require("@/assets/img/san.png"),
                title: "Young talents with doctoral degrees from first-class universities at home and abroad."
            }
        ],
    },

    missionvisionvalues: { // 关于我们-使命愿景价值观
        MVV_tipOne: "mission",
        MVV_tipTwo: "Focusing on exploring the path of memory manufacturing technology, establishing an innovative system led by enterprises and combining industry, academia and research, and promoting the continuous iteration of memory technology.",
        MVV_tipThree: "vision",
        MVV_tipFour: "It has become the world's memory chip technology innovation highland, intellectual property highland, talent training highland and international cooperation bridge.",
        MVV_tipFive: "sense of worth"
    },

    briefIntroduction: { // 关于我们-简介
        BI_tip: "brief introduction"
    },

    memorabilia: { // 关于我们-发展大事记
        mb_tip: "Development Memorabilia"
    },

    recruitment: { // 人才招聘
        RT_textOne: "Diversity, equality and respect for work life balance",
        RT_textTwo: "Talent recruitment is the current important strategy of Superstring Research Institute. The rapid growth of the Institute provides an excellent platform for the career development of young talents - there are business mentors with rich industrial experience, a cultural atmosphere that encourages innovation and inclusiveness, an advanced 12 inch DRAM manufacturing hardware platform, and a close cooperation mechanism with industrial production lines and the best academic institutions in China, The opportunity to participate in large-scale projects oriented to mass production technology research and development... The Superstring Research Institute warmly welcomes young talents at home and abroad to join us and achieve their best in the process of achieving great cause.",
        RT_modular: [
            {
                name: "Talent policy",
                path: "talentPolicy"
            },
            {
                name: "Career",
                path: "talentRecruitment"
            },
            {
                name: "Remunerationbenefits train and develop",
                path: "salaryWelfare"
            },
            {
                name: "work environment",
                path: "workEnvironment"
            },
            {
                name: "Employee stories",
                path: "employeeStories"
            },
            {
                name: "Joint talent training plan",
                path: "personnelTraining"
            },
        ]
    },

    talentPolicy: { // 人才招聘-人才政策
        TP_tipOne: "Our vision, mission and values are based on the diversity of talents and mutual respect. Innovation and cooperation are our values, which will be created by our diversified talents. We embrace the power of diversity as a catalyst for creativity and disruptive innovation.",
        TP_tipTwo: "We want to establish an equal relationship between all employees. We respect your differences in gender, religious belief, race, age, nationality and other aspects.We are committed to creating a non discriminatory working environment shared by all employees, so that everyone can show their best.",
        TP_tipThree: "",
        TP_tipFour: "The physical and mental health of employees is very important to us. We hope that employees can not only enjoy the sense of achievement brought by work, but also feel the joy of happy life.We hope to establish a long-term win-win relationship with our employees, provide a stage for their career development, and grow up with them to create a better future.",
        TP_tipFive: "",
        TP_tipSix: "diversification",
        TP_tipSeven: "Equality respect",
        TP_tipEight: "Work life balance",
    },

    salaryWelfare: { // 人才招聘-薪酬福利 培训发展
        SW_tipOne: "Remuneration and benefits",
        SW_modular: ["Competitive salary in the industry, six insurances and one fund", "Beijing hukou", "Transportation subsidy and meal subsidy", "Talent apartment<br>(doctor single room)", "Paid annual leave (legal+welfare)", "Annual Checkup", "Holiday gifts and birthday greetings"],
        SW_tipTwo: "Training and development",
        // SW_modularTwo: [
        //     {
        //         title: "On the job training On the job guidance of international top tutors",
        //         img: require("@/assets/img/mvv_aaa.png")
        //     },
        //     {
        //         title: "Off-work courses Expert training course of national talent plan",
        //         img: require("@/assets/img/mvv_aaa.png")
        //     },
        //     {
        //         title: "Attend first-class international / domestic academic conferences",
        //         img: require("@/assets/img/mvv_aaa.png")
        //     },
        //     {
        //         title: "Patent application incentive scheme",
        //         img: require("@/assets/img/mvv_aaa.png")
        //     }
        // ]
    },

    workEnvironment: { // 人才招聘-工作环境
        WE_message: "President's message",
        WE_messageTwo: "Thank our employees for choosing superstring Research Institute as a platform for career development. The rapid growth of the academy itself provides an excellent platform for the career development of young talents - there are business instructors with rich industrial experience, a cultural atmosphere that encourages innovation and inclusiveness, an advanced 12 inch DRAM manufacturing hardware platform, and a close cooperation mechanism with industrial production lines and the best academic institutions in China, The opportunity to participate in large-scale projects for mass production technology research and development... Superstring academy warmly welcomes young talents at home and abroad to join us and realize their best in the process of achieving great causes.",
        WE_tipOne: "Office environment",
        WE_tipOneOne: "Every morning full of vitality, the four seasons flowers and fresh air of jingdongbei science and technology park come in front of you. The first ray of sunshine in the morning spilled over the desk, so we began a fruitful day.",
        WE_tipTwo: "Staff club",
        WE_tipTwoTwo: "Badminton club, reading club and fitness club enrich our spare time life, relax our body and mind and promote friendship.",
        WE_tipThree: "League building activities",
        WE_tipThreeThree: "Creative group building activities bring us different life experiences."
    },

    employeeStories: { // 人才招聘-员工故事
        ES_modular: [
            // {
            //     name: "Jin Junhao",
            //     img: require('@/assets/img/esBJ1.png'),
            //     // <br>Why do I choose superstring?
            //     tipOne: "High end international talents",
            //     tipTwo: "Hello, I'm from Lichuan, Korea. I feel very honored to work in the Institute. I have a very happy family. The family members are my wife, a pair of lovely twin daughters and a naughty dog. They will also come to China at the end of this year. The Superstring Research Institute is pregnant with the future of the memory industry. The Institute's culture is inclusive, encouraging innovation, and people-oriented. I hope that I can contribute to the future development of the industry together with scientists from Belgium, Singapore, and the United States."
            // },
            {
                name: "Zhang Feng",
                img: require('@/assets/img/esBJ2.png'),
                // <br>Why do I choose superstring?
                tipOne: "Experienced professionals",
                tipTwo: "I come from Hulunbeier, Inner Mongolia, and have successively worked in China Electronics Technology Group and Intel Corporation. My research direction is mainly advanced lithography technology. My hobbies are reading and Qin Qin, and my life vision is to become myself in the process of progress. The Superstring Research Institute has gathered a group of like-minded people. We support and learn from each other, and can really engage in some exploratory, experimental and influential projects for the future development of the industry. The Institute has made meticulous arrangements in terms of housing, school registration, household registration and other aspects of life, and also attaches great importance to a harmonious and relaxed working atmosphere. I am very satisfied with this job."
            },
            {
                name: "Mary Li",
                img: require('@/assets/img/esBJ3.png'),
                // <br>Why do I choose superstring?
                tipOne: "Fresh student",
                tipTwo: "I graduated from the School of Materials Science and Engineering of Beijing University of Science and Technology with an undergraduate degree. Later, I received a doctorate in physics from William and Mary College in the United States. I usually like fitness, swimming, running, mountain climbing, volleyball and other projects, and I also like to watch some science fiction, suspense, and reasoning movies and books. When I came to the Superstring Research Institute, I met many industry masters, who could work under their guidance, study project system on the industrial production line, and participate in domestic training courses and international academic conferences. I felt that I was growing too fast!"
            }
        ]
    },

    talentRecruitment: { // 人才招聘-人才招聘
        // TR_navList: [
        //     { name: "Social recruitment", type: 0 },
        //     { name: "Campus Recruitment", type: 1 },
        //     { name: "Joint training of talents (Doctor / Master)", type: 2 }
        // ],
        TR_tipOne: "employ",
        TR_tipOne_br: "employ",
        TR_tipTwo: "see",
        TR_tipThree: "Put away",
        TR_tipFour: "people",
    },

    industryService: { // 行业服务
        IS_navList: [
            {
                name: "R&D services",
            },
            {
                name: "personnel training",
            },
            {
                name: "Patent service",
            },
            {
                name: "academic activities",
            },
            {
                name: "Industry science popularization",
            },
        ],
        IS_titleOne: "Beijing superstring memory research institute provides comprehensive services to promote the overall development of the memory industry - establishing public R & D platforms and services in the industry, cultivating industry talents, providing patent services, holding academic activities and popular science publicity, promoting academic exchanges and international cooperation, and expanding the social influence of the memory industry.",
        IS_modular: [
            {
                tip: "R & D Service",
                title: "Beijing superstring memory research institute provides a public R & D platform and R & D services for the memory industry.",
                see: "see",
                path: "R&DService"
            },
            {
                tip: "personnel training",
                title: "Beijing superstring memory research institute cooperates with top universities and research institutes in China to build a talent training highland and reserve talents for the development of the industry.",
                see: "see",
                path: "personnelTraining"
            },
            {
                tip: "Patent service",
                title: "Promoting and implementing intellectual property cooperation in the whole industry chain is an important service content of Beijing superstring memory research institute.",
                see: "see",
                path: "patentService"
            },
            {
                tip: "academic activities",
                title: "Beijing superstring memory research institute regularly holds domestic / international academic conferences to promote industrial academic exchanges and international cooperation.",
                see: "see",
                path: "academicActivities"
            },
            {
                tip: "Industry science popularization",
                title: "Beijing superstring Memory Research Institute promotes the popularization of Science in the memory industry and helps expand the social influence of the industry.",
                see: "see",
                path: "sciencePopularization"
            },
        ]
    },

    personnelTraining: { // 行业服务-人才培养
        PT_tipOne: "Joint training of talents of Beijing superstring Memory Research Institute",
        PT_tipTwo: "Beijing superstring memory research institute has established a perfect talent training mechanism and is committed to building a bridge between education and industry and promoting the deep integration of industry and education. As an important participant of the production and education integration base of Beijing Yizhuang Economic Development Zone, the joint training program of engineering doctor and Engineering Master between the Institute and Tsinghua University, Peking University and the school of integrated circuits of the University of Chinese Academy of Sciences can train a large number of high-end integrated circuit talents for the industry every year.",
        PT_tipThree: "The talent training program of Beijing superstring memory research institute has the following advantages",
        PT_tipFour: "Welcome to participate in the industry-education integration project of the Superstring Memory Research Institute to achieve dreams together.",
        PT_modular: [
            {
                name: "Problem oriented research topic",
                icon: require("@/assets/img/yi.png")
            },
            {
                name: "Experienced enterprise tutor team",
                icon: require("@/assets/img/er.png")
            },
            {
                name: "World advanced R & D conditions",
                icon: require("@/assets/img/san.png")
            },
            {
                name: "Rich teaching and training courses",
                icon: require("@/assets/img/si.png")
            },
            {
                name: "Students are immersed in the working atmosphere of industrial R & D",
                icon: require("@/assets/img/wu.png")
            },
            {
                name: "After finishing school,You can join the research institute or be recommended to work in leading memory design and manufacturing enterprises",
                icon: require("@/assets/img/liu.png")
            },
        ]
    },

    patentService: { // 行业服务-专利服务
        PS_modular: [
            {
                title: "At the beginning of its establishment, Beijing superstring Memory Research Institute attached great importance to intellectual property work and took the construction of 'intellectual property highland' as one of its visions. Therefore, the research institute has set up a professional intellectual property team to serve the intellectual property business of the Research Institute."
            },
            {
                title: "The integrated circuit industry is not only a technology intensive industry, but also an intellectual property intensive industry. Based on the current situation of the industry, the intellectual property team of the research institute tracks the development trend of technology, warns intellectual property risks, promotes the layout of intellectual property, and is committed to improving the operation ability of industrial intellectual property protection, risk prevention and high-value patents."
            },
            {
                title: "The intellectual property team consists of professionals in law, technology, information analysis and management. At present, we have carried out patent mining, layout, patent early warning, patent database construction, intellectual property training and other work, and continue to promote the construction of an intellectual property cooperation platform between government departments, industry cooperation institutions, universities and enterprises."
            },
        ],
        PS_TipOne: "Patent qualification",
        PS_TipTwo: "Patent service"
    },

    sciencePopularization: { // 行业服务-行业科普
        SP_tipOne: "Industry science popularization",
        SP_modular: [
            {
                name: "Memorabilia of memory development",
                img: "",
                see: "see",
                path: "storageDevelopment"
            },
            // {
            //     name: "Hall of fame for integrated circuits",
            //     img: "",
            //     see: "see"
            // },
            {
                name: "What is DRAM",
                img: "",
                see: "see",
                path: "bannerDetails"
            },
            {
                name: "Popular science news",
                img: "",
                see: "see",
                path: "scienceNews"
            },
            // {
            //     name: "library",
            //     img: "",
            //     see: "see",
            //     path: "library"
            // },
        ],
        SP_modularTwo: [
            {
                name: "Memorabilia of memory development",
                img: "",
                see: "see",
                path: "storageDevelopment"
            },
            // {
            //     name: "Hall of fame for integrated circuits",
            //     img: "",
            //     see: "see"
            // },
            {
                name: "What is DRAM",
                img: "",
                see: "see",
                path: "bannerDetails"
            },
            // {
            //     name: "Popular science news",
            //     img: "",
            //     see: "see",
            //     path: "scienceNews"
            // },
            {
                name: "library",
                img: "",
                see: "see",
                path: "library"
            },
        ],
        library: { // 行业服务-行业科普-图书馆
            L_download: "download",
            L_tipOne: "full name:",
            L_tipTwo: "label:",
        },
        scienceNews: { // 行业服务-行业科普-图书馆
            SN_tip: "Popular science news",
            SN_see: "see"
        },
        storageDevelopment: { // 行业服务-行业科普-储存器发展大事记
            SD_tip: "Memorabilia of storage development "
        }
    },

    RDService: { // 行业服务-研发服务
        RDS_tipOne: "EDA Tool validation and import.TEG Design and layout generation.PDK Development and verification",
        RDS_tipTwo: "Integration of integrated circuit production and education and cultivation of high-end talents",
        RDS_tipThree: "Integrated circuit advanced process flow and technical consultation",
        RDS_tipFour: "Customized wafer substrate and pattern structure sheet processing",
        RDS_tipFive: "R & D Service",
        RDS_tipSix: "Analysis of advanced process and technology patent of integrated circuit memory",
        RDS_tipSeven: "Equipment and material verification",
        RDS_tipEight: "Semiconductor material characterization and wafer level electrical testing services",
    },

    TechnologyRD: { // 技术研发
        RD_navList: [
            {
                name: "R&D direction",
            },
            {
                name: "R&D conditions",
            },
            {
                name: "Department settings",
            },
            {
                name: "Scientific research achievements",
            },
        ],
        RD_titleOne: "Beijing Chaoxian Memory Research Institute conducts future-oriented research and development of memory chip technology based on industry development trends, focusing on new memory architectures, new materials, new processes, and other fields. It has advanced the layout of a batch of revolutionary and innovative technologies, focused on tackling a batch of key core technologies, implemented and transformed a batch of mature technologies, and promoted the long-term development of the memory industry.",
        RD_modular: [
            {
                tip: "Research direction",
                title: "Beijing superstring memory research institute provides a public R & D platform and R & D services for the memory industry.",
                see: "see",
                path: "researchDirection"
            },
            {
                tip: "R & D conditions",
                title: "Superstring Research Institute has the hardware conditions required for the development of 12 inch advanced DRAM, including the traditional 1x DRAM full process basic process and a series of short process characteristic processes composed of special equipment; Design the software platform for collaborative optimization of manufacturing; And a technical team with rich experience in chip technology and design and development.",
                see: "see",
                path: "RDconditions"
            },
            {
                tip: "Department settings",
                title: "The Technology Research and Development Department of Beijing Superstring Memory Research Institute consists of six departments, namely, Design and Product Department, Advanced Lithography and Technology Research and Development Department, New Materials and Domestic Equipment Verification Department, Advanced Process Research and Development Department, Process Integration and Advanced Packaging Technology Research and Development Department.",
                see: "see",
                path: "departmentSettings"
            },
            {
                tip: "Scientific research achievements",
                title: "The main form of scientific research achievements of Beijing superstring memory research institute is to provide technical Iteration Solutions for domestic DRAM enterprises. Scientific discoveries in the research and development process will be published in the form of papers, and technological innovation achievements will be reflected in patent applications.",
                see: "see",
                path: "scientificAchievements"
            },
        ]
    },

    RDconditions: { // 技术研发-研发条件
        RD_tipOne: "R & D conditions",
        RD_tipTwo: "Superstring Research Institute has the hardware conditions required for the development of 12 inch advanced DRAM, including the traditional 1x DRAM full process basic process and a series of short process characteristic processes composed of special equipment; Design the software platform for collaborative optimization of manufacturing; And a technical team with rich experience in chip technology and design and development.",
    },

    scientificAchievements: { // 技术研发-科研成果
        SA_tipOne: "Scientific research articles",
        SA_tipTwo: "Scientific research patent",
        SA_tipThree: "periodical",
        SA_see: "see"
    },

    researchDirection: { // 技术研发-研发方向
        RD_tipOne: "R & D direction",
        RD_titleOne: "Chaoxian Research Institute focuses on the research and development of DRAM manufacturing technology. In the fields of DRAM new architecture, new materials, new processes, advanced packaging, lithography technology, near memory computing, and high bandwidth storage, it conducts systematic process research and development and design manufacturing collaborative optimization on the main paths that may exist in future DRAM mass production technology.",
        RD_titleTwo: "At present, the main research projects include memory manufacturing technologies based on new device structures, such as vertical gate transistor dynamic random access memory, indium gallium zinc oxide field-effect transistor dynamic random access memory, lateral stacked cell memory, vertical gate transistor magnetic memory, etc; Design and manufacturing of high bandwidth memory chips, near memory computing, in memory computing, and other chips based on hybrid bonding technology; And research on key lithography and design technology methodology in DRAM manufacturing.",
        RD_tipTwo: "R & D project",
        RD_see: "see"
    },

    departmentSettings: { // 技术研发-部门设置
        DS_tipOne: "Department settings",
        DS_modular: [
            {
                img: require('@/assets/img/dsBJ1.png'),
                title: "Design and product department",
                // titleTwo: "Turn ideas into reality.",
                type: 1,
                see: "see"
            },
            {
                img: require('@/assets/img/dsBJ2.png'),
                title: "Advanced lithography technology R & D department",
                // titleTwo: "Lithography is a key step in semiconductor manufacturing.",
                type: 2,
                see: "see"
            },
            {
                img: require('@/assets/img/dsBJ3.png'),
                title: "Process R&D Platform Department",
                // titleTwo: "Advanced process R & D department",
                type: 3,
                see: "see"
            },
            {
                img: require('@/assets/img/dsBJ4.png'),
                title: "Process Integration Department",
                // titleTwo: "Advanced technology is the bridge between layout design and manufacturing.",
                type: 4,
                see: "see"
            },
            {
                img: require('@/assets/img/dsBJ5.png'),
                title: "New Materials and Equipment Verification Department.",
                // titleTwo: "The project management department is the link of the combination of production, study and research of the Research Institute.",
                type: 5,
                see: "see"
            },
            {
                img: require('@/assets/img/dsBJ6.png'),
                title: "Advanced packaging technology R & D department",
                // titleTwo: "",
                type: 6,
                see: "see"
            },
        ]
    },

    designProductDepartment: { // 技术研发-部门设置-设计与产品部
        DPD_oneTitle: "Design and Product Department",
        DPD_oneCont: "In the development process of semiconductor industry, design has always played a key role in each new technology from initial research and development to industrialization. It is not only responsible for product performance, but also for yield improvement, cost reduction, reliability, product standardization, etc. The Design&Product Department focuses on the international frontier and combines the industrial development trend with the process department to carry out the exploration and development of forward-looking new storage technologies. The research field starts from the basic devices at the bottom, goes up to the memory design and functional verification, and then to the computing storage architecture at a higher level.",
        DPD_twoTip: ["Research is not a destination.", "Design is a key step in the commercialization of technology/products.", "Products are the bridge connecting design and market."],
        DPD_threeTip: "The role of design",
        DPD_fourTipOne: "scope of business",
        DPD_fourContOne: "Design and Product Department",
        DPD_fourContOneOne: "It is divided into design group (Design), computer aided engineering group (CAE), design implementation group (DET) and system group (System).",
        DPD_fourTipTwo: "Design Group",
        DPD_fourContTwo: "The design team is responsible for the functional verification of new storage technologies, collaborative optimization of design processes, exploration of new storage circuits and intellectual property layout, covering circuit design, simulation, layout, verification, streaming, verification, patent application, etc. On the one hand, it helps the research and development of new storage technologies at the process level, and on the other hand, it should make efforts in advance at the circuit design level, Strive to occupy a place in the competition for new storage technologies in the future. At the same time, the Design Department has also actively established project cooperation and in-depth talent training plans with enterprises, universities and scientific research institutions to cultivate urgently needed design talents for the development of China's memory industry.",
        DPD_fourTipThree: "CAE Group",
        DPD_fourContThree: "CAE group (Computer Aided Engineering) is mainly responsible for DRAM design methodology. CAE is responsible for computer aided design (CAD) and device. Because of the diversity of DRAM products and the time urgency of product development, the traditional DRAM design methods can not meet the needs. CAD will effectively shorten the design time and reduce the labor cost by developing and introducing automatic design methods and establishing a design library. The work of devices relies on the theory and experience of device physics and process, carries out innovation on new devices, analyzes the relationship between device electrical properties and process, and is responsible for device simulation to provide support for manufacturing process research.",
        DPD_fourTipFour: "DET Group",
        DPD_fourContFour: "With the increasing complexity of semiconductor technology, the design and manufacture of DRAM are facing huge challenges. With huge film feeding costs, it is necessary to ensure a successful film feeding. Therefore, it is very important to set device specifications, plan test units, simulate processes and devices, develop process design kits and device models, and predict device characteristics and circuit feasibility in advance. At the same time, it is also a realistic and urgent task for us to try to develop and verify the specific functions of domestic EDA in new devices and new architectures.",
        DPD_fourContFourOne: "The DET team will work with EDA manufacturers, circuit designers and OEMs to solve the challenges faced by EDA tools. As a communication link between EDA manufacturers, OEMs and designers, the DET department specifically performs many tasks, including process design kits, device models, layout design and process and device simulation, and the import and verification of domestic EDAs. We combine the functions of device design, process and device simulation, process design kit and device model, and optimize the process of multiple design schemes to generate the key database required for the final circuit design.",
        DPD_fourTipFive: "System Group",
        DPD_fourContFiveOne: "With the end of Moore's Law and the rapid growth of global data · The 'Neumann' architecture is increasingly facing many limitations, such as the shortage of computing and memory resources. The bandwidth wall and power wall between processors and memory, between processors and external memory are increasingly becoming obstacles to improving computer performance and reducing energy consumption; On the other hand, in the face of growing unstructured data, traditional file systems are no longer efficient. At the same time, as 'Feng · The weakest memory among the elements of the Neumann architecture is also faced with a series of problems such as difficulty in further improving the storage density, reducing the leakage current and dynamic power consumption. In the post Moore era, the iteration of existing memory technology and the development of new storage technology are no longer an isolated problem, and must be integrated into the whole system for comprehensive consideration.",
        DPD_fourContFiveTwo: "New data centric computing storage architecture",
        // DPD_fourContFiveThree: "The main work of the system department of the Superstring Memory Research Institute will focus on the development of the core technology of a new data centric computing storage architecture, which is used to increase the degree of parallelism of computing and/or reduce the restrictions on existing computing, memory, external memory and I/O, and reduce the energy consumption caused by massive data movement. At the same time, it focuses on solving the architecture problems in DRAM iterative technology and new storage technology.",
        DPD_fourContFiveThree: "",
        DPD_fourContFiveFour: "Research direction of system group",
        DPD_fourContFiveFive: [{
            name: "DRAM based near memory computing",
            img: require('@/assets/img/DPDimg7.png'),
        }, {
            name: "Computational storage",
            img: require('@/assets/img/DPDimg8.png'),
        }, {
            name: "DRAM iteration technology and architecture issues in new storage technology",
            img: require('@/assets/img/DPDimg9.png'),
        }, {
            name: "CXL DRAM",
            img: require('@/assets/img/DPDimg10.png'),
        }]
    },

    lithographySection: { // 技术研发-部门设置-先进光刻技术研发部
        LS_oneTitle: "Advanced Photolithography Technology R&D Department",
        LS_oneCont: "Lithography is the core technology of IC manufacturing and the key driver of the evolution of Moore's Law. The development of lithography technology has driven the chip process to continuously shrink key dimensions, making integrated circuits evolve to smaller technology nodes to achieve higher performance, higher density and lower power consumption. The R&D Department of lithography technology explores and researches cutting-edge technologies, pre studies various advanced lithography technology routes of memory technology, and promotes the improvement of the next generation of advanced memory mass production technology; It provides strong support for the research and development of new memory architectures and devices, new materials and process integration. At the same time, research and develop collaborative optimization of light source masks, optical proximity effect correction, collaborative optimization of design and manufacturing, advanced mask production and manufacturing, and efficiency improvement based on machine learning; Verify that new lithography materials and equipment are introduced into the production line. We also actively carry out technical cooperation between universities and research institutions in the field of basic scientific research, and continue to explore cutting-edge scientific and technological development in the field of lithography technology; Establish an industry university research talent platform to cultivate high-end R&D talents with profound theoretical basis for the further development of China's integrated circuit industry.",
        LS_threeTip: "Business scope",
        LS_threeModular: [
            {
                name: "Research and development of advanced lithography technology:<br/>Imaging in advanced technology<br/>Precise alignment<br/>Precise overprint",
                img: require('@/assets/img/lsThreeImg1.png'),
            }, {
                name: "Micro imaging improvement and optical optimization<br/>Machine learning of sub resolution aided graphics",
                img: require('@/assets/img/lsThreeImg2.png'),
            }, {
                name: "DRAM and new emerging storage products<br/>Research and development of lithography process",
                img: require('@/assets/img/lsThreeImg3.png'),
            }, {
                name: "Verification of localized photolithography materials and equipment",
                img: require('@/assets/img/lsThreeImg4.png'),
            }, {
                name: "Optical Proximity Effect Correction Optimization",
                img: require('@/assets/img/lsThreeImg5.png'),
            }, {
                name: "Lithography production, teaching and research platform<br/>Integration of production and education and talent cultivation",
                img: require('@/assets/img/lsThreeImg6.png'),
            },
        ],
        LS_fourTipOne: "Graphical enhancement technology",
        LS_fourContOne: ["Core array", "Standard unit", "IP module", "Test Design Layout", "Existing product layout", "Product similar chip layout", "Key figures"],
        LS_fourContTwo: ["Dummy Fill and Optimize", "Litho Friendly Design", "CMP Modeling and Analysis", "Key area analysis", "Weakness Graph Library"],
        LS_fourContThree: ["Lithography simulation", "Light source optimization", "OPC model", "OPC correction", "Inspection after OPC correction"],
        LS_fourTipTwo: "OPC refinement",
        LS_fourContFour: ["Layer logic operation", "Mask layout design", "Photomask plate making data", "JDV/MRC/MCD", "Mask Order and Delivery", "Mask quality monitoring", "MPC"],
        LS_fourTipThree: "Design and micro imaging process",
    },

    processIntegrationDepartment: { // 技术研发-部门设置-工艺集成部
        PID_oneTitle: "Process Integration Department",
        PID_oneCont: "The Process Integration Department is responsible for communicating and coordinating the development and integration of various processes during the research and development of new technologies in the integrated circuit storage field; Responsible for process formulation, offline arrangement and online management of R&D line Lot; Continuously track chip test results and analyze and solve problems; Assist in layout design and change, and promote tape out of R&D projects. As the core force in project R&D, the Process Integration Department actively explores new technologies and schemes, cooperates with design, materials, process and other modules, and completes the key steps of project R&D process flow formulation, device streaming, WAT electrical test and analysis.",
        PID_fourTipOne: "Business scope",
        PID_fourContOne: ["Project organization and implementation", "New process evaluation and process establishment", "Process integration and optimization", "R&D Lot Management", "Electrical test and analysis of structural verification", "Tape out support"],
        PID_fourTipTwo: "R&D projects",
    },

    processPlatformDepartment: { // 技术研发-部门设置-工艺研发平台部
        PPD_oneTitle: "Process R&D Platform Department",
        PPD_oneCont: "The Advanced Process R&D Department is the top R&D department for new materials, new processes and new structures in China. Through research on basic disciplines such as optics, materials science, chemistry, fluid mechanics, plasma physics, mechanical engineering, etc., it designs advanced DRAM manufacturing processes, finds a feasible path for chip manufacturing and production, and promotes the development of domestic integrated circuit processes.",
        PPD_twoTitle: "The working standard of the Process Platform R&D Department is<text style='color: #283b7e !important;'> Pursue details and perfection</text>",
        PPD_threeTitle: "Support all R&D projects",
        PPD_threeModular: [
            {
                name: "film",
                img: require('@/assets/img/ppdThreeImg1.png'),
            }, {
                name: "spread",
                img: require('@/assets/img/ppdThreeImg2.png'),
            }, {
                name: "Photoetching",
                img: require('@/assets/img/ppdThreeImg3.png'),
            }, {
                name: "Etching",
                img: require('@/assets/img/ppdThreeImg4.png'),
            }, {
                name: "CMP",
                img: require('@/assets/img/ppdThreeImg5.png'),
            }, {
                name: "clean",
                img: require('@/assets/img/ppdThreeImg6.png'),
            },
        ],
        PPD_threeTip: "<text style='font-weight: bold;'>The Process R&D Platform Department explores the cutting-edge six module processes</text> —— Film, diffusion, photolithography, etching, chemical grinding and cleaning are integrated into the best applicable through collaborative optimization",
        PPD_fourTip: "Advantages of Process R&D Platform Department",
        PPD_fourModular: [
            {
                name: "A team of senior engineers with rich industry experience.<br/> <br/> 12 inch advanced DRAM process development hardware platform.",
                img: require('@/assets/img/ppdImg3.png'),
            },
            {
                name: "17nm DRAMbasic process.<br/> <br/>Multiple sets of advanced equipment for new materials and new processes.",
                img: require('@/assets/img/ppdImg4.png'),
            }
        ]
    },

    materialPackaging: { // 技术研发-部门设置-新材料与国产装备验证部
        MP_oneTitle: "New Materials and Equipment Verification Department",
        MP_oneCont: "Semiconductor materials and manufacturing equipment are one of the indispensable supporting industries for the iteration of integrated circuit technology nodes. The verification department of new materials and domestic equipment shall lead the layout of new material research and development in the storage field, focus on tackling the core technology of material application, and establish collaborative optimization from materials to systems(MSCO:Material to System Co-Optimization) platform to continuously improve device performance; Cooperate with equipment manufacturers to guide and assist equipment in key technology research and development in the field of advanced technology research and development, expand equipment process capabilities, build a harmonious ecological environment for China's integrated circuit industry, and promote the sustainable and circular development of the entire industry.",
        MP_twoTipOne: "Building a collaborative innovation ecosystem of equipment and materials",
        MP_twoTipTwo: "Build a verification platform for localized equipment and materials, and provide R&D assistance services - high-quality structural pieces and customized graphic pieces.",
        MP_twoModular: ["Epitaxy process<br/>for 3D-DRAM", "New supplier's<br/> Equipment verification", "Equipment and material verification", "DRAM new<br/>Material<br/>ALD process", "With suppliers<br/>Cooperative development", "Research and development of new materials", "Oxide semiconductivity<br/>Bulk film deposition", "New supplier's<br/>Material verification"],
        MP_threeTipOne: "Advanced SiGe process research and development",
        MP_threeContOne: "Si/SiGe epitaxial growth",
        MP_threeContTwo: "The periodic growth of Si/SiGe epitaxial layers is the key to the multi lateral cell stacking DRAM technology.",
        MP_threeModular: ["Surface and interface<br/>analysis", "Physical dimensions", "SiGe film<br/>Material characterization", "Film composition<br/>analysis", "single crystal<br/>analysis", "strain analysis"],
        MP_fourTipOne: "ALD process",
        MP_fourContTwo: "With the continuous miniaturization of DRAM cell devices, it is necessary to deposit high conformal films on high aspect ratio structures and surround structures. Atomic layer deposition (ALD) has become the only solution due to its precise film thickness control ability and conformability. ALD technology, including oxide semiconductor channel materials, dielectrics and various metal films, is becoming a hot topic in process research.",
        PPD_fiveTip: "IGZO film",
        PPD_fiveCont: "IGZO has wide band gap and high carrier mobility at the same time. As a channel material of transistors, IGZO can provide ultra-high switching ratio and is expected to realize 2T0C DRAM. IGZO thin film growth is the key to the manufacture of IGZO based DRAM, and is becoming one of the research focuses of memory manufacturing technology.",
        PPD_sixTip: "Material verification",
        PPD_sixContOne: "Composition of China's IC manufacturing materials market in 2021",
        PPD_sixContTwo: "As a bridge between wafer manufacturing enterprises and material suppliers, provide verification services for DRAM manufacturing materials for suppliers;",
        PPD_sixContThree: "Provide industry technical consultation to suppliers and help them develop consumables for new materials and processes.",
        PPD_sevenTip: "Equipment verification",
        PPD_sevenContOne: "Market scale composition of China's integrated circuit manufacturing equipment in 2021",
        PPD_sevenContTwo: "Provide traditional DRAM process validation services for equipment of new suppliers;",
        PPD_sevenContThree: "Cooperate with suppliers to develop new equipment for new materials;",
        PPD_sevenContFour: "Provide structural pieces required for process development for equipment research and development."
    },


    SItemap: { // 头部网站地图
        SI_tip: "SItemap",
        SI_modular: [
            [
                {
                    name: "Technology R & D"
                },
                {
                    name: "Research direction",
                    path: "researchDirection"
                },
                {
                    name: "R & D conditions",
                    path: "RDconditions"
                },
                {
                    name: "Department settings",
                    path: "departmentSettings"
                },
                {
                    name: "Scientific research achievements",
                    path: "scientificAchievements"
                },
            ],
            [
                {
                    name: "Industry service"
                },
                {
                    name: "R & D Service",
                    path: "R&DService"
                },
                {
                    name: "personnel training",
                    path: "personnelTraining"
                },
                {
                    name: "Patent service",
                    path: "patentService"
                },
                {
                    name: "academic activities",
                    path: "academicActivities"
                },
                {
                    name: "Industry science popularization",
                    path: "sciencePopularization"
                },
            ],
            [
                {
                    name: "Career"
                },
                {
                    name: "Talent policy",
                    path: "talentPolicy"
                },
                {
                    name: "Career",
                    path: "talentRecruitment"
                },
                {
                    name: "Salary and welfare training and development",
                    path: "salaryWelfare"
                },
                {
                    name: "work environment",
                    path: "workEnvironment"
                },
                {
                    name: "Employee stories",
                    path: "employeeStories"
                },
                {
                    name: "Joint talent training plan",
                    path: "personnelTraining"
                },
            ],
            [
                {
                    name: "News Center"
                },
                {
                    name: "News Center",
                    path: "newsCenter"
                },
            ],
            [
                {
                    name: "About us"
                },
                {
                    name: "brief introduction",
                    path: "briefIntroduction"
                },
                {
                    name: "Mission vision values",
                    path: "missionvision-values"
                },
                {
                    name: "Our team",
                    path: "ourTeam"
                },
                {
                    name: "Qualification and honor",
                    path: "qualificationHonor"
                },
                {
                    name: "Development milestones",
                    path: "memorabilia"
                },
            ]
        ]
    },


    register: { // 注册
        tipOne: "register",
        tabbar: ["individual", "Unit"]
    },

    newsCenter: { // 新闻中心
        NC_see: "see"
    }


} 