import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/swiper',
    name: 'swiper',
    component: () => import('../views/swiper.vue'),
    meta: {
      title: "注册",
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: {
      title: "网站首页",
      index: 0,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs/aboutUs.vue'),
    meta: {
      title: "关于我们",
      index: 6,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/qualificationHonor',
    name: 'qualificationHonor',
    component: () => import('../views/aboutUs/qualificationHonor.vue'),
    meta: {
      title: "资质和荣誉",
      index: 6,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/ourTeam',
    name: 'ourTeam',
    component: () => import('../views/aboutUs/ourTeam.vue'),
    meta: {
      title: "我们的团队",
      index: 6,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/missionvision-values',
    name: 'missionvision-values',
    component: () => import('../views/aboutUs/missionvision-values.vue'),
    meta: {
      title: "使命愿景价值观",
      index: 6,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/briefIntroduction',
    name: 'briefIntroduction',
    component: () => import('../views/aboutUs/briefIntroduction.vue'),
    meta: {
      title: "简介",
      index: 6,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/memorabilia',
    name: 'memorabilia',
    component: () => import('../views/aboutUs/memorabilia.vue'),
    meta: {
      title: "发展大事记",
      index: 6,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import('../views/recruitment/recruitment.vue'),
    meta: {
      title: "人才招聘",
      index: 4,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/employeeStories',
    name: 'employeeStories',
    component: () => import('../views/recruitment/employeeStories.vue'),
    meta: {
      title: "员工故事",
      index: 4,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/salaryWelfare',
    name: 'salaryWelfare',
    component: () => import('../views/recruitment/salaryWelfare.vue'),
    meta: {
      title: "薪酬福利 培训发展",
      index: 4,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/talentPolicy',
    name: 'talentPolicy',
    component: () => import('../views/recruitment/talentPolicy.vue'),
    meta: {
      title: "人才政策",
      index: 4,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/workEnvironment',
    name: 'workEnvironment',
    component: () => import('../views/recruitment/workEnvironment.vue'),
    meta: {
      title: "工作环境",
      index: 4,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/talentRecruitment',
    name: 'talentRecruitment',
    component: () => import('../views/recruitment/talentRecruitment.vue'),
    meta: {
      title: "人才招聘",
      index: 4,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('../views/newsCenter/newsCenter.vue'),
    meta: {
      title: "新闻中心",
      index: 5,
      footShow: true,
      headShow: true
    }
  }, {
    path: '/newsCenterDetails',
    name: 'newsCenterDetails',
    component: () => import('../views/newsCenter/newsCenterDetails.vue'),
    meta: {
      title: "新闻中心详情",
      index: 5,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/industryService',
    name: 'industryService',
    component: () => import('../views/industryService/industryService.vue'),
    meta: {
      title: "行业服务",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/personnelTraining',
    name: 'personnelTraining',
    component: () => import('../views/industryService/personnelTraining.vue'),
    meta: {
      title: "人才培养",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/R&DService',
    name: 'R&DService',
    component: () => import('../views/industryService/R&DService.vue'),
    meta: {
      title: "研发服务",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/patentService',
    name: 'patentService',
    component: () => import('../views/industryService/patentService.vue'),
    meta: {
      title: "专利服务",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/academicActivities',
    name: 'academicActivities',
    component: () => import('../views/industryService/academicActivities.vue'),
    meta: {
      title: "学术活动",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/sciencePopularization',
    name: 'sciencePopularization',
    component: () => import('../views/industryService/sciencePopularization.vue'),
    meta: {
      title: "行业科普",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/library',
    name: 'library',
    component: () => import('../views/industryService/library.vue'),
    meta: {
      title: "图书馆",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/scienceNews',
    name: 'scienceNews',
    component: () => import('../views/industryService/scienceNews.vue'),
    meta: {
      title: "科普新闻",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/storageDevelopment',
    name: 'storageDevelopment',
    component: () => import('../views/industryService/storageDevelopment.vue'),
    meta: {
      title: "储存器发展大事记",
      index: 2,
      footShow: false,
      headShow: true
    }
  },
  {
    path: '/TechnologyRD',
    name: 'TechnologyRD',
    component: () => import('../views/TechnologyRD/TechnologyRD.vue'),
    meta: {
      title: "技术研发",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/RDconditions',
    name: 'RDconditions',
    component: () => import('../views/TechnologyRD/RDconditions.vue'),
    meta: {
      title: "研发条件",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/scientificAchievements',
    name: 'scientificAchievements',
    component: () => import('../views/TechnologyRD/scientificAchievements.vue'),
    meta: {
      title: "科研成果",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/journalDetails',
    name: 'journalDetails',
    component: () => import('../views/TechnologyRD/journalDetails.vue'),
    meta: {
      title: "期刊详情",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/researchDirection',
    name: 'researchDirection',
    component: () => import('../views/TechnologyRD/researchDirection.vue'),
    meta: {
      title: "研究方向",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/RDproject',
    name: 'RDproject',
    component: () => import('../views/TechnologyRD/RDproject.vue'),
    meta: {
      title: "项目详情",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/departmentSettings',
    name: 'departmentSettings',
    component: () => import('../views/TechnologyRD/departmentSettings.vue'),
    meta: {
      title: "部门设置",
      index: 1,
      footShow: true,
      headShow: true
    }
  }, {
    path: '/designProductDepartment',
    name: 'designProductDepartment',
    component: () => import('../views/TechnologyRD/designProductDepartment.vue'),
    meta: {
      title: "设计与产品部",
      index: 1,
      footShow: true,
      headShow: true
    }
  }, {
    path: '/lithographySection',
    name: 'lithographySection',
    component: () => import('../views/TechnologyRD/lithographySection.vue'),
    meta: {
      title: "先进光刻技术研发部",
      index: 1,
      footShow: true,
      headShow: true
    }
  },{
    path: '/processIntegrationDepartment',
    name: 'processIntegrationDepartment',
    component: () => import('../views/TechnologyRD/processIntegrationDepartment.vue'),
    meta: {
      title: "工艺集成部",
      index: 1,
      footShow: true,
      headShow: true
    }
  },{
    path: '/processPlatformDepartment',
    name: 'processPlatformDepartment',
    component: () => import('../views/TechnologyRD/processPlatformDepartment.vue'),
    meta: {
      title: "工艺研发平台部",
      index: 1,
      footShow: true,
      headShow: true
    }
  },{
    path: '/materialPackaging',
    name: 'materialPackaging',
    component: () => import('../views/TechnologyRD/materialPackaging.vue'),
    meta: {
      title: "新材料与国产装备验证部",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/Sitemap',
    name: 'Sitemap',
    component: () => import('../views/headerPage/Sitemap.vue'),
    meta: {
      title: "Sitemap",
      index: -1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/privacyStatement',
    name: 'privacyStatement',
    component: () => import('../views/headerPage/privacyStatement.vue'),
    meta: {
      title: "",
      index: -1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
    meta: {
      title: "注册",
      footShow: false,
      headShow: false
    }
  },
  {
    path: '/bannerDetails',
    name: 'bannerDetails',
    component: () => import('../views/bannerDetails.vue'),
    meta: {
      title: "详情",
      footShow: true,
      headShow: true
    }
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  //页面跳转显示在顶部   
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// 全局前置守卫：任何页面的访问都要经过这里
// to：要去哪里的路由信息
// from：从哪里来的路由信息
// next：通行的标志
// router.beforeEach((to, from, next) => {
//   document.title = '北京超弦存储器研究院';
//   next()
// })


// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
