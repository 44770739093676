import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 封装axios
import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs'
Vue.prototype.$qs = qs

// npm i lib-flexible-computer -S //根节点会根据页面视口变化而变化font-size大小
// npm i px2rem-loader -D//自动将px转换为rem
// npm i postcss-px2rem//将代码中px自动转化成对应的rem的一个插件
import "lib-flexible-computer";


import VueI18n from 'vue-i18n' //引入vue-i18n
import i18n from './assets/js/language/lang.js'  //引入lang.js
Vue.use(VueI18n)//使用

import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'

import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

//导入全局请求
import request from '@/utils/request.js';
Vue.prototype.BASE_URL = request.BASE_URL
Vue.prototype.$request = request.request;
import tool from './utils/tool.js'
Vue.use(tool)

// 滚动
import scroll from "vue-seamless-scroll"
Vue.use(scroll)

// 动画库
import animated from 'animate.css'
Vue.use(animated)

// jquery
import $ from 'jquery'
Vue.prototype.$ = $

// 视频组件
// import VideoPlayer from "vue-video-player";
// // import 'video.js/dist/video-js.css';

// // import 'vue-video-player/src/custom-theme.css';

// Vue.use(VideoPlayer)

import dragscroll from '@/utils/directives.js'; // 引入鼠标拖拽滚动图片指令文件
// 注册鼠标拖拽滚动图片指令
Vue.directive('dragscroll', dragscroll);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,//挂载在实例中
  render: h => h(App)
}).$mount('#app')
