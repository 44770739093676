<template>
  <div :class="$route.meta.headShow == false ? 'headBoxOne' : 'headBoxTwo'">
    <div class="head animate__animated animate__fadeInDown">
      <div class="headNavTitle" :style="'backgroundImage:url(' + require('@/assets/img/topBJ.jpg') + ')'
        ">
        <div class="titleLeft" v-if="userInfo != '' && userInfo != null">
          <el-popover placement="top" trigger="hover" content="">
            <div class="signIn">
              <!-- 审核状态:0=待审核,1=通过,2=拒绝 -->
              <!-- <div class="userInfo">
                {{ $i18n.locale == "zh" ? "审核状态：" : "Audit status:" }}
                {{
                  userInfo.check_status == 0
                    ? "待审核"
                    : userInfo.check_status == 1
                    ? "审核通过"
                    : userInfo.check_status == 2
                    ? "拒绝"
                    : ""
                }}
              </div> -->
              <div class="userInfo"> {{ $i18n.locale == "zh" ? "姓名：" : "full name:" }}{{ userInfo.username }} </div>
              <div class="userInfo"> {{ $i18n.locale == "zh" ? "邮箱：" : "e_mail:" }} {{ userInfo.email }} </div>
              <div class="userInfo"> {{ $i18n.locale == "zh" ? "身份：" : "identity:" }} {{ userInfo.card_type_name }} </div>
              <div class="inTwo">
                <div @click="register(3)" v-if="userInfo.check_status != 0"> {{ $i18n.locale == "zh" ? "修改资料" : "Modify data" }} </div>
                <div @click="LogOut" class="LogOut"> {{ $i18n.locale == "zh" ? "退出登录" : "Log out" }} </div>
              </div>
            </div>
            <div slot="reference">
              <img src="@/assets/img/userLogo.png" alt="" class="userLogo" />
              <div class="title"> {{ userInfo.username == "" ? "未完善资料" : userInfo.username }} </div>
              <img src="@/assets/img/xiajiantou.png" alt="" class="xiajiantou" />
            </div>
          </el-popover>
        </div>
        <div class="titleLeft" v-if="userInfo == '' || userInfo == null" @click="register(1)">
          <img src="@/assets/img/userLogo.png" alt="" class="userLogo" />
          <div class="title"> {{ $t("publicHead.signInTip") }} </div>
          <img src="@/assets/img/xiajiantou.png" alt="" class="xiajiantou" />
        </div>
        <div class="titleRight">
          <div class="title titleTwo" v-for="(item, index) in $t('publicHead.titleList')" :key="index"
            @click="index == 0 ? '' : titleItem(item, index)">
            <div v-if="index == 0">
              <el-popover placement="top" trigger="hover" content="">
                <div class="code">
                  <div>
                    <img :src="info.qygzh" alt="" />
                    <div>企业公众号</div>
                  </div>
                  <div>
                    <img :src="info.qywx" alt="" />
                    <div>企业微信</div>
                  </div>
                </div>
                <div slot="reference">{{ item.title }}</div>
              </el-popover>
            </div>
            <div v-if="index != 0"> {{ item.title }} </div>
          </div>
          <!-- <div class="titleThree" @click="languageChange">
            <img src="@/assets/img/qiehuan.png" alt="" class="qiehuan" />
            <div class="title">{{ $t("publicHead.language") }}</div>
          </div> -->
        </div>
      </div>
      <div class="headNav settingStyle" :style="'backgroundImage:url(' + require('@/assets/img/headBJ.jpg') + ')'
        " v-show="$route.meta.headShow">
        <div v-for="(item, index) in navOneList" :key="index" :class="listIndex == index
            ? 'active listItem listItem' + index
            : 'listItem listItem' + index
          " @click="navChange(index, item)">
          <div v-if="index == 0 || index == 1 || index == 2" class="listItemLeft">
            <div class="navTitle">{{ item.title }}</div>
            <div class="navTitle_en">{{ item.title_en }}</div>
          </div>
          <img :src="item.img" alt="" class="logo" v-if="index == 3" />
          <div v-if="index == 4 || index == 5 || index == 6" class="listItemRight">
            <div class="navTitle">{{ item.title }}</div>
            <div class="navTitle_en">{{ item.title_en }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：公用头部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-03-14 10:16
 */
export default {
  inject: ["reload"], // 刷新页面
  data() {
    return {
      navOneList: [
        {
          title: "网站首页",
          title_en: "Homepage",
          path: "/",
        },
        {
          title: "技术研发",
          title_en: "Our expertise",
          path: "TechnologyRD",
        },
        {
          title: "行业服务",
          title_en: "What we offer",
          path: "industryService",
        },
        {
          img: require("@/assets/img/logo.png"),
        },
        {
          title: "人才招聘",
          title_en: "Career",
          path: "recruitment",
        },
        {
          title: "新闻中心",
          title_en: "News center",
          path: "newsCenter",
        },
        {
          title: "关于我们",
          title_en: "About us",
          path: "aboutUs",
        },
      ],
      listIndex: 0,

      info: "",

      userInfo: "",
      token: "",
    };
  },
  mounted() {
    // console.log(this.$i18n);
    const that = this;
    that.getUserInfo();
    window.addEventListener("setItemEvent", function (e) {
      // let newdata = JSON.parse(e.newValue);
      that.getUserInfo();
    });

    // 二维码
    this.$request({
      url: "/Index/info",
    }).then((res) => {
      // console.log("关于我们", res);
      this.info = res;
    });

    window.addEventListener("scroll", this.handleScrollbox, true);
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      this.listIndex = to.meta.index;
    },
  },
  methods: {
    // nav切换  刘嘉鑫  2022-8-10
    navChange(index, item) {
      this.listIndex = index;
      this.$router.push({
        path: item.path,
      });
    },

    /**
     * 头部切换
     * 刘嘉鑫
     * 2022-8-10
     */
    titleItem(item, index) {
      if (index == 3) {
        this.$router.push({
          path: item.path,
        });
      } else {
        this.$router.push({
          path: item.path,
          query: {
            type: item.type,
          },
        });
      }
    },

    // 语言切换  刘嘉鑫  2022-8-10
    languageChange() {
      return
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "zh";
      } else {
        this.$i18n.locale = "en";
      }
      this.reload();
    },

    /**
     * 登录，修改资料
     * 刘嘉鑫
     * 2022-8-24
     */
    register(type) {
      this.$router.push({
        path: "register",
        query: {
          type: type,
        },
      });
    },

    /**
     * 退出登录
     * 刘嘉鑫
     * 2022-8-24
     */
    LogOut() {
      localStorage.removeItem("token");
      this.userInfo = "";
      this.reload();
    },

    /**
     * 获取用户信息
     * 刘嘉鑫
     * 2022-8-25
     */
    getUserInfo() {
      setTimeout(() => {
        console.log("token-Head", localStorage.getItem("token"));
        this.$request({
          url: "/User/userInfo",
          data: {
            token: localStorage.getItem("token"),
          },
        })
          .then((res) => {
            console.log("用户信息", res);
            this.userInfo = res.userinfo;
          })
          .catch((error) => {
            this.userInfo = "";
            localStorage.removeItem("token");
          });
      }, 200);
    },

    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll <= 10) {
        this.$(".headNav").removeClass("fixed");
      } else {
        this.$(".headNav").addClass("fixed");
      }
    },
  },
};
</script>
<style>
.el-popover {
  padding: 0 !important;
}
</style>
<style lang='scss' scoped>
.headBJ {
  width: 100%;
  height: 140px;
  vertical-align: bottom;
}

.headBoxTwo {
  height: 188px;
}

.headBoxOne {
  height: 48px;
}

.head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.headNavTitle {
  padding: 13px 260px;
  // background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.titleLeft {
  display: flex;
  align-items: center;
}

.titleRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.userLogo {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  cursor: pointer;
}

.titleTwo {
  font-weight: 400 !important;
  margin-right: 30px;
}

.titleThree {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.titleThree div {
  font-weight: 400 !important;
}

.qiehuan {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.xiajiantou {
  width: 8px;
  height: 4px;
  margin-left: 6px;
}

.titleLeft div {
  display: flex;
  align-items: center;
}

.headNav {
  // background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 240px;
  height: 140px;
  transition: height 0.3s;
  -ms-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -webkit-transition: height 0.3s;
  -o-transition: height 0.3s;

  .listItem {
    cursor: pointer;
    text-align: center;
    width: 8%;

    .navTitle {
      font-size: 18px;
      font-family: FZCuHeiSongS-B-GB;
      font-weight: bold;
      color: #222222;
      overflow: hidden;
    }

    .navTitle_en {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #aaaaaa;
      margin-top: 8px;
      overflow: hidden;
    }
  }

  .listItem3 {
    display: flex;
    justify-content: center;
    width: 20%;
  }

  .active div {
    color: #1a2a60 !important;
  }

  .logo {
    width: 150px;
    height: 61px;
    transition: all 0.3s;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
  }
}

.fixed {
  height: 90px;
  transition: height 0.3s;
  -ms-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -webkit-transition: height 0.3s;
  -o-transition: height 0.3s;

  .listItem3 {
    .logo {
      width: 130px !important;
      height: 51px !important;
    }
  }
}

.code {
  padding: 19px 50px;
  display: flex;
  align-items: center;

  >div:last-child {
    margin-left: 40px;
  }

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  div {
    font-size: 14px;
    font-family: FZFengYaSongS-GB;
    font-weight: 400;
    color: #1a2a60;
    text-align: center;
  }
}

.signIn {
  width: 250px;
  padding: 20px 30px;

  .userInfo {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    margin-bottom: 12px;
  }

  .inTwo {
    margin-top: 30px;
    display: flex;
    align-items: center;

    >div {
      background: #1a2a60;
      padding: 10px 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      border-radius: 6px;
      cursor: pointer;
    }

    .LogOut {
      background: #eee !important;
      color: #1a2a60 !important;
      margin-left: 30px !important;
    }
  }
}
</style>